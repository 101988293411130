import store from '@/store'
import { useRouter } from '@core/utils/utils'

export const useEcommerce = () => {
  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = productId => {
    return store.dispatch('ecommerce/addProductInWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = productId => {
    return store.dispatch('ecommerce/removeProductFromWishlist', { productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = item => {
    const data = {
      item_id: item.id,
      quantity: item.quantity === undefined ? 1 : item.quantity,
      add_one: item.quantity === undefined ? 1 : null,
    }
    return store.dispatch('ecommerce/addProductInCart', data)
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = productId => {
    return store.dispatch('ecommerce/removeProductFromCart', { item_id: productId })
  }

  const getCurrentItemsInCart = () => store.getters['ecommerce/getCurrentItemsInCart']

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,

    // Getters
    getCurrentItemsInCart,
  }
}

export const useEcommerceUi = () => {
  const { router } = useRouter()

  const toggleProductInWishlist = product => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }

  const handleCartActionClick = item => {
    const { addProductInCart } = useEcommerce()
    addProductInCart(item).then(() => {
      // eslint-disable-next-line no-param-reassign
      item.is_in_cart = true

      // Update cart items count
      store.commit('ecommerce/UPDATE_CART_ITEMS_COUNT', store.state.ecommerce.cartItemsCount + 1)
    })
  }

  const handleWishlistCartActionClick = (item, removeProductFromWishlistUi) => {
    const { addProductInCart } = useEcommerce()

    if (item.is_in_cart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(item)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.is_in_cart = true
          // Update cart items count
          store.commit('ecommerce/UPDATE_CART_ITEMS_COUNT', store.state.ecommerce.cartItemsCount + 1)
        })
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.isInWishlist = false
          removeProductFromWishlistUi(item)
        })
    }
  }
  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
  }
}
